// import React, { useState } from 'react';

// const Container2 = ({ children }) => {
//     // Define the items array for filter categories
//     const items = [
//         "Collection",
//         "Disbursement",
//         "Offline payment",
//         "Customer integration",
//         "SDK"
//     ];

//     // State to keep track of selected filter categories
//     const [selectedItems, setSelectedItems] = useState([]);

//     // Handle checkbox change 
//     const handleCheckboxChange = (event, item) => {
//         if (event.target.checked) {
//             setSelectedItems(prev => [...prev, item]);
//         } else {
//             setSelectedItems(prev => prev.filter(i => i !== item));
//         }
//     };

//     return (
//         <div className="flex w-full items-start my-5">
         
//             <div className="md:px-7 md:mx-7 mt-8 base-doc-color w-8/12">
               
//                 {React.Children.map(children, child => {
//                     return React.cloneElement(child, { selectedItems });
//                 })}
//             </div>

           
//             <div className="md:px-7 md:mx-7 mt-8 border-l border-grey-500 w-3/12">
//                 <div className="py-7">
//                     <p className="pb-5 text-lg base-doc-color">Filter</p>
//                     <ul>
//                         {items.map((item, index) => (
//                             <li key={index} className="px-2 base-doc-color text-base pb-2">
//                                 <label>
//                                     <input
//                                         type="checkbox"
//                                         className="mr-2"
//                                         checked={selectedItems.includes(item)}
//                                         onChange={(event) => handleCheckboxChange(event, item)}
//                                     />
//                                     {item}
//                                 </label>
//                             </li>
//                         ))}
//                     </ul>
//                     <p className="pt-5 text-base monnify-primary">Clear Filter</p>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Container2;


import React, { useState } from 'react';

const Container2 = ({ children }) => {
    // Define the items array for filter categories
    const items = [
        "Collection",
        "Disbursement",
        "Offline payment",
        "Customer integration",
        "SDK"
    ];

    // State to keep track of selected filter categories
    const [selectedItems, setSelectedItems] = useState([]);

    // Handle checkbox change 
    const handleCheckboxChange = (event, item) => {
        if (event.target.checked) {
            setSelectedItems(prev => [...prev, item]);
        } else {
            setSelectedItems(prev => prev.filter(i => i !== item));
        }
    };

    // Function to clear all selected filters
    const clearFilters = () => {
        setSelectedItems([]); // Reset selected items
    };

    return (
        <div className="flex w-full items-start my-5">
         
            <div className="md:px-7 md:mx-7 mt-8 base-doc-color w-8/12">
               
                {React.Children.map(children, child => {
                    return React.cloneElement(child, { selectedItems });
                })}
            </div>

           
            <div className="md:px-7 md:mx-7 mt-8 border-l border-grey-500 w-3/12">
                <div className="py-7">
                    <p className="pb-5 text-lg base-doc-color">Filter</p>
                    <ul>
                        {items.map((item, index) => (
                            <li key={index} className="px-2 base-doc-color text-base pb-2">
                                <label>
                                    <input
                                        type="checkbox"
                                        className="mr-2"
                                        checked={selectedItems.includes(item)}
                                        onChange={(event) => handleCheckboxChange(event, item)}
                                    />
                                    {item}
                                </label>
                            </li>
                        ))}
                    </ul>
                    {/* Clear Filter Button */}
                    <p 
                        className="pt-5 text-base monnify-primary cursor-pointer" 
                        onClick={clearFilters}
                    >
                        Clear Filter
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Container2;

