import React from 'react'; 

const Container3 = ({children}) => (
    <div className="md:px-7 md:mx-7 mt-8 base-doc-color border-b pb-3">
        <div className="md:px-0 ">
            {children}
        </div>
    </div>
)

export default Container3;