import React from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Image from './image';

import transferLogoSVG from "../images/transferLogo.svg"; 
import transferLogoPNG from "../images/transferLogo.png"; 
import logoSVG from "../images/monnifyLogo.svg"; 
import logoPNG from "../images/monnifyLogo.png"; 

const MonnifyLogo = ({ maintext, subtext, image }) => {

  // Function to handle download of files
 

  const downloadLogoFiles = async () => {
    console.log("Download started!");
  
    const zip = new JSZip();
  
    // Function to fetch image as a blob
    const fetchImageAsBlob = async (imagePath) => {
      const response = await fetch(imagePath);
      return response.blob();
    };
  
    try {
      // Fetch images
      const [svgBlob, pngBlob, svgBlob2, pngBlob2] = await Promise.all([
        fetchImageAsBlob(transferLogoSVG),
        fetchImageAsBlob(transferLogoPNG),
        fetchImageAsBlob(logoSVG),
        fetchImageAsBlob(logoPNG),
      ]);
  
      // Add images to zip with proper binary data
      zip.file("monnify-logo.svg", svgBlob);
      zip.file("monnify-logo.png", pngBlob);
      zip.file("monnifyLogo.svg", svgBlob2);
      zip.file("monnifyLogo.png", pngBlob2);
  
      // Generate zip file and trigger download
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "monnify-logos.zip");
    } catch (err) {
      console.error("Error fetching images:", err);
    }
  };
  

  return (
    <div className="md:col-span-12 md:flex items-center justify-between">
      <div className="text-section lg:w-5/12 md:w-6/12 sm:w-full">
        <h1 className="font-semibold monnify-color-3 text-2xl md:text-3xl pb-5">{maintext}</h1>
        <p className="text-base/7 text-gray-500 font-light mb-2 ">{subtext}</p>
      </div>
      <div 
        className="logo-section flex justify-center items-center lg:w-4/12 md:w-5/12 sm:w-full border rounded-xl cursor-pointer"
        onClick={downloadLogoFiles} // Attaching click event on the parent div
      >
        <div className="flex w-7/12 my-10">
          <Image
            src={image}
            alt="Logo"
            className=""
          />
        </div>
      </div>
    </div>
  );
};

export default MonnifyLogo;
