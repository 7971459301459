import React from 'react';
import Button from './button';
import Image from './image';
import { Link } from 'gatsby';

const Sdklist = ({ title = "", desc = "", image = "", linkurl = "#" }) => {

    return (
        <>
            <div className={"col md:mr-4 md:px-8 px-4 pt-9 pb-0 card card-green flex flex-col"}>
                <h2 className="text-xl font-semibold">{title}</h2>
                <div className="text-gray-700 py-1 text-base base-doc-color mb-4">{desc}</div>
                <div className="grid grid-cols-4 mt-2 gap-4">
                    <div className="col-span-2 py-5">
                        <Button text="Web" ><Image src="web.svg" classes='p-0 m-0' /></Button>
                    </div>
                    <div className="col-span-2 py-5">
                        <Button text="IOS"><Image src="ios.svg" classes='p-0 m-0' /></Button>
                    </div>
                </div>
                <div className="grid grid-cols-4 mt-2 gap-4">
                    <div className="col-span-2 py-5">
                        <Button text="Android" ><Image src="android.svg" classes='p-0 m-0' /></Button>
                    </div>
                    <div className="col-span-2 py-5">
                        <Button text="Flutter"><Image src="flutter.svg" classes='p-0 m-0' /></Button>
                    </div>
                </div>
                <div className='py-5'>
                    <Link to={linkurl}>
                        <Image src={image} classes="mt-6 mx-2 mb-0 mt-auto" /></Link>
                </div> 
            </div>
        </>
    )

}
export default Sdklist
