import React from 'react';
import Heading from './heading';

function convertToSlug(Text) {
    return Text.toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-').trim();
}

const Changelog = ({ title = "", tag = "", description = "", arrList = "", selectedItems = [] , date=""}) => {
    // Check if the current changelog's tag is in selectedItems
    const isVisible = selectedItems.length === 0 || selectedItems.includes(tag);  

    // If the category is not selected, don't render this Changelog item
    if (!isVisible) return null;

    return (
        <>
            <div className='my-8 w-full'>
                <div className='md:flex items-center justify-between '>
                    <div className='md:flex items-center  pb-2'>
                    <Heading h={6} lp={false} text={title} key={convertToSlug(title)} />
                    <div className="flex items-end mx-4 bg-[#0B32750A] text-base font-bold px-4 py-2 rounded inline uppercase text-[#0B327566]">
                        {tag}
                    </div>
                    </div>
                  
                  <div className='md:w-2/12 flex justify-end '>
                    <p className='text-base font-bold text-black'>
                        {date}
                    </p>
                  </div>
                </div>

                <p className='mt-2' style={{ overflowWrap: "break-word" }}>
                    {description}

                    <ul className="mt-1 list-disc marker:text-orange-500 space-y-2 list-outside ml-4 mx-5 my-3">
                        {arrList && arrList.length > 0 && ("" + arrList).split("///").map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </p>
            </div>
        </>
    );
};

export default Changelog;
