import React from 'react';
import Image from './image';
import { Link } from 'gatsby';
import Wrapper from './wrapper';

const Longcard = ({ title, description, image, text = false, url = false, type = false }) => {
    return (
        <>
            <Wrapper  smaller="yes">
                    <div className={((type) ? "monnify-bg-color-3" : "monnify-bg-color-2") + " grid grid-cols-12 mt-7 pb-0 p-5   "}>
                        <div className="col-span-12  md:mr-4 md:col-span-6 px-6 my-5">
                            <h1 className={((type) ? "monnify-color-3 " : "text-white ") + "font-bold text-2xl pb-5 sm:text-xs md:text-3xl"} >{title} </h1>
                            <p className={((type) ? "base-doc-color " : "text-white ") + " mb-6 text-xs md:text-base font-thin"}>{description}</p>
                            {(text === false || url === false) ? '' : <Link to="/api" style={{ background: "linear-gradient(147.87deg, #F0AA22 -8.91%, #F05822 99.52%)", borderRadius: "4px" }} className="text-white px-7 py-4 my-4 text-xs md:text-sm px-3 ">  Learn more </Link>}
                        </div>
                        <div className="hidden md:block col-span-0 md:col-span-1"></div>
                        <div className="col-span-12 md:mr-7 md:col-span-5 mb-0 mt-7 md:mt-10 ml-2 flex flex-col pr-1">
                            <Image src={image} classes="align-bottom b-0 pb-0 mt-auto  hover:drop-shadow-md hover:scale-105 hover:translate-y-5" />
                        </div>
                    </div>
             </Wrapper>
        </>
    )
}
export default Longcard;