import React from 'react'; 
import Image from './image';

const Masthead = ({ title, description, image }) => {
    return ( <>
    
    <div className="faded-mast-head -mx-3 md:mx-0 md:pb-6 pb-2 mo-b-b-primary ">
                <div className="mx-auto px-2 md:px-9 md:mx-9 pt-7 grid grid-cols-7 md:grid-cols-12">
                    <div className="col-span-7 px-3 md:px-5 px-1 py-7 flex justify-center items-center  ">
                       <div>
                        <h1 className="font-semibold text-3xl md:text-5xl md:pt-5 monnify-color-1" style={{fontFamily:"font-family: 'Oxygen Mono', monospace"}}>{ title } 
                        </h1>

                        <p className="py-3 md:py-5 text-lg font-thin base-doc-color md:pr-5">
                          { description }
                        </p>
                        </div>
                    </div>
                
                    <div className="hidden md:block col-span-4 py-7  md:mt-11 ">
                        <Image src={image} classes="w-full h-full" />
                    </div>
                </div>
            </div></>)
     
    }

export default Masthead;