import React from 'react';
import logoUrl from '../images/logo.svg';
import { Link } from 'gatsby';
import Heading from './heading';

function convertToSlug(Text) {
    return Text.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-').trim();
}


const Logodownload = (title = "", src = "", description = "", arrList = "") => {
    return (
        <>
            <div className='my-8'>
                {/* <Heading h={6} lp={false} text={title} key={convertToSlug(title)} /> */}
                

                <div className=" ml-0 mt-2 md:mt-0  ">
                    <Link to="/" className="font-bold text-xl text-indigo-600 mt-2 md:mt-0 ">
                        <img src={logoUrl} alt="Monnify" className="py-2 ml-0 my-0 min-monnify-h2" />
                    </Link>
                </div>

                <p className='mt-1 ' style={{ overflowWrap: "break-word" }}>
                    {description}
                    <ul className="mt-1 list-disc marker:text-orange-500 space-y-2 list-outside ml-4 mx-5 my-3">
                        {
                            arrList && arrList.length > 0 && ("" + arrList).split("///").map((item) => {
                                return (<li>{item}</li>)
                            })
                        }
                    </ul>
                </p></div>
        </>
    )
}

export default Logodownload;