import React from 'react';
 

const Titledesc = ({ title, desc }) => {
    return (
        <div className="md:px-7 md:mx-7 mt-7">
            <div className="md:px-7 mx-2">
                <h1 className="font-semibold text-2xl md:text-3xl md:pt-5 monnify-color-1"  > {title} </h1>
                <p className=" lg:w-3/5 sm:w-full py-2 base-doc-color text-lg">{desc}</p>
            </div>
        </div>
    )
}

export default Titledesc;