import React from 'react';
import Image from './image';
import { Link } from 'gatsby';
import Wrapper from './wrapper';

const Banner = ({ title, description, type = false }) => {
    return (
        <>
            <Wrapper  smaller="yes">
                    <div className={((type) ? "monnify-bg-color-3" : "monnify-bg-color-2") + " grid grid-cols-12 mt-7 pb-0 p-5   "}>
                        <div className="col-span-12  md:mr-4 md:col-12 px-3 my-5">
                            <h1 className={((type) ? "monnify-color-3 " : "text-white ") + "font-bold text-2xl pb-5 sm:text-xs md:text-3xl"} >{title} </h1>
                            <p className={((type) ? "base-doc-color " : "text-white ") + " mb-6 text-xs md:text-base font-thin"}>{description}</p>
                           
                        </div>
                    </div>
             </Wrapper>
        </>
    )
}
export default Banner;