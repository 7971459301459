import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Badge from "../components/badge"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"
// import { YouTube, Twitter, TomatoBox } from "./ui"
import Bigcard from "../components/bigcard"
import codepen from "@digitalocean/do-markdownit/rules/embeds/codepen"
import CodeHighlight from "../components/codehighlight"
import Paragraph from "../components/paragraph"
import Youtube from "../components/youtube"
import Changelog from '../components/changelog';
// import styled from "styled-components";
import Nav from "../components/nav"
import DevImage from "../images/monnify-dev-2.svg"
import Card1 from "../images/card-1.svg"
import Card2 from "../images/card-2.svg"
import Card3 from "../images/card-3.svg"
import Card4 from "../images/card-4.svg"
import RefImage from "../images/reference.svg"
import ViewButtomImage from "../images/view-arrow.svg"
import Apicontainer from "../components/apicontainer"
import Maincontainer from "../components/maincontainer"
import Alert from "../components/alert"
import Image from "../components/image"
import Breadcrumb from "../components/breadcrumb"
import Accordion from "../components/accordion"
import { Helmet } from "react-helmet"
import Sidebar from "../components/sidebar"
import Favicon from '../images/favicon.png'
import Twocolumn from "../components/twocolumn"
import Sdklist from "../components/sdklist"
import Button from "../components/button"
import Footer from "../components/footer"
import Masthead from "../components/masthead"
import Longcard from "../components/longcard"
import Hr from "../components/hr"
import Titledesc from "../components/titledesc"
import Halfcard from "../components/halfcard"
import Cardwrapper from "../components/cardwrapper"
import Smartcard from "../components/smartcard"
import AutoComplete from "../components/autocomplete"
import Threecard from "../components/threecard"
import Fourcard from "../components/fourcard"
import Container1 from "../components/container1"
import Container2 from "../components/container2"
import Container3 from '../components/container3';
import Msparser from "../components/msparser"
import Heading from "../components/heading"
import Headsm from "../components/headsm"
import Imagedropdown from "../components/imagedropdown"
import { ScrollToTop } from "../components/scrolltotop"
import Pricing from "../components/pricing"
import ScrollToButton from "../components/scrolltobutton"
import StarRating from "../components/StartRating/StarRating";
import Borderholder from "../components/borderholder"
import FeatureRequestForm from "../components/featurerequest"
// import Loader from '../components/loader';
import Logodownload from '../components/logodownload';
import  MonnifyLogo from '../components/monnifylogo'
import Banner from '../components/banner';

// const md = require('markdown-it')({}).use(require('@digitalocean/do-markdownit'), {});

// let components = {
//   row: Row,
//   col: Col,
//   // badge: Badge,
// };

const shortcodes = { Link, Msparser,Headsm,Imagedropdown,Banner,FeatureRequestForm,Container3, StarRating, Container2, MonnifyLogo, Borderholder, Logodownload, Changelog,  ScrollToButton, Pricing, ScrollToTop, Threecard, Heading, Container1, Fourcard,  AutoComplete,  Halfcard, Smartcard, Cardwrapper, Titledesc, Hr, Longcard, Masthead, Breadcrumb, Youtube, Paragraph, CodeHighlight, codepen, Bigcard, Badge, Alert, Nav, Image, Accordion, Sdklist, Button, Footer, Twocolumn, DevImage, Card1, Card2, Card3, Card4, RefImage, ViewButtomImage, Apicontainer, Maincontainer }


export default function Template({ data }) {
  // console.log(data);
  const { mdx, sitePage } = data
  const { frontmatter, id, body } = mdx
  const { path } = sitePage
  const allPosts = data.allMarkdownRemark.edges
  const allAPI = data.allCollectionJson.edges[0].node //.item.item

  const [onthispage, setOnthispage] = useState({});

  const [activeDocsmenu, setactiveDocsmenu] = useState(false);

  useEffect(() => {
    if( activeDocsmenu || ! localStorage.getItem('dataKey') ){
      localStorage.setItem('dataKey', JSON.stringify(activeDocsmenu));
    }
  }, [activeDocsmenu]);
 
  return (

    <>
      {/* <MD components={components} htmlAst={hast} />        <meta name="viewport" content="width=1024" /> */}
      {/* <MDXProvider components={shortcodes}><MDXRenderer>{body}</MDXRenderer></MDXProvider> */}
      <Helmet>
        <title>{frontmatter.title}</title>
        <meta name="description" content="Monnify Developer Documentation " />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#03a9f4" />
        <link rel="shortcut icon" href={Favicon} type="image/x-icon" />
        <meta property="og:url"           content={frontmatter.path} />
        <meta property="og:type"          content="website" />
        <meta property="og:title"         content={((frontmatter.title)?frontmatter.title+" -":"") + " Monnify Documentation"} />
        <meta property="og:description"   content={(frontmatter.description)?frontmatter.description:frontmatter.title+" - Monnify Developer Documentation"} />
        <meta property="og:image"         content={Favicon} />
        <meta name="keywords" content="Monnify, Monnify Documantation, API reference, Moniepoint, Reserved Account, Developer Documentation" data-react-helmet="true" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@monnify" />
        <meta name="twitter:title" content={((frontmatter.title)?frontmatter.title+" -":"") + " Monnify Documentation"} />
        <meta name="twitter:description" content={(frontmatter.description)?frontmatter.description:frontmatter.title+" - Monnify Developer Documentation"} />
        <meta name="twitter:image" content={Favicon} />

         {/* <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version={graph-api-version}&appId={your-facebook-app-id}&autoLogAppEvents=1"  nonce="FOKrbAYI">
  </script> */}
      </Helmet>
      <div  >
         <Borderholder cols={2}  >
          <Sidebar activeDocsmenu={activeDocsmenu} setactiveDocsmenu={setactiveDocsmenu} ></Sidebar>
          <Maincontainer> 
            <Nav path={path} allPosts={allPosts} allAPI={allAPI} ></Nav>
              <Apicontainer setOnthispage={setOnthispage} onthispage={onthispage} showshortlink={( frontmatter.ignoreshortlink === true )?false : true } shortlinks={frontmatter.shortlinks}>
                 <MDXProvider components={{ ...shortcodes }}><MDXRenderer>{ body }</MDXRenderer></MDXProvider>
              </Apicontainer>
            <Footer path={frontmatter.path} />
          </Maincontainer>
        </Borderholder>

      </div>
    </>

  )
}



export const pageQuery = graphql`
 query($path: String!) {
  mdx(frontmatter: { path: { eq: $path } }) {
    body
    id
    frontmatter {
       path
      title
      description
      ignoreshortlink
    } 
  }
    allCollectionJson {
      edges {
        node {
          item {
            item {
              name
              request {
                description
                method
              }
            }
          }
        }
      }
    }
    sitePage {
      path
    }
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___path }) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          frontmatter {
            title
            path
            description
          } 
          html
        }
      }
    }
}
` 
 // edges.node.frontmatter.title
 /*
<button data-modal-target="popup-modal" data-modal-toggle="popup-modal" class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
  Toggle modal
</button>

<div id="popup-modal" tabindex="-1" class="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
    <div class="relative w-full h-full max-w-md md:h-auto">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="popup-modal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Close modal</span>
            </button>
            <div class="p-6 text-center">
                <svg aria-hidden="true" class="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this product?</h3>
                <button data-modal-hide="popup-modal" type="button" class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                    Yes, I'm sure
                </button>
                <button data-modal-hide="popup-modal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button>
            </div>
        </div>
    </div>
</div>
 */